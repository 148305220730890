import * as React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Hero from '../Components/Hero/Hero';
import QuickAbout from '../Components/QuickAbout/QuickAbout';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import WhatWeDo from '../Components/WhatWedo/WhatWeDo';
import QuickShop from '../Components/QuickShop/QuickShop';
import QuickTestimonies from '../Components/QuickTestimonies/QuickTestimonies';
import Goals from '../Components/Goals/Goals';
import SEO from '../Components/seo';

// markup
const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Header />
    <Hero />
    <main id="main">
      <QuickAbout />
      <SectionHeader
        heading="What we do"
        subheading="SheIsBrave empowers, mentors, educate, teaches, and account for every young woman through the following :"
      />
      <WhatWeDo />
      <SectionHeader
        heading="Brave Merch"
        subheading="There are so many ways to show your bravery, and one of them is wearing this awesome merch"
      />
      <QuickShop />
      <QuickTestimonies />
      <Goals />
    </main>
    <Footer />
  </>
);

export default IndexPage;
