import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ heading, subheading }) => (
  <div className="container">

    <div className="section-header">
      <h2>{heading}</h2>
      <p>
        {subheading}
      </p>
    </div>
  </div>
);

SectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};
SectionHeader.defaultProps = {
  subheading: '',
};

export default SectionHeader;
