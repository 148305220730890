import React from 'react';
import {
  FaBrain, FaHandPaper,
} from 'react-icons/fa';
import { BsLaptopFill, BsDropletFill } from 'react-icons/bs';
import './whatWeDo.css';

const WhatWeDo = () => (
  <section id="whatwedo">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="box">
            <div className="icon">
              <BsDropletFill />
            </div>
            <h4 className="title">Ending Period Poverty</h4>
            <p className="description">
              We donate and provide access to sanitary products, menstrual education, and hygiene.
            </p>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="box">
            <div className="icon">
              <BsLaptopFill />
            </div>
            <h4 className="title">Training digital Skills</h4>
            <p className="description">
              We equip young people with computer skills and fundamental digital skills
              such as web development, digital marketing, graphic design, analytics,
              and networking to access information, from basic online
              searching and emailing to programming and development.
            </p>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="box">
            <div className="icon">
              <FaBrain />
            </div>
            <h4 className="title">Personal Development</h4>
            <p className="description">
              We provide personal development programs with skills that involve the
              process of self-improvement,which focuses on strengthening one&apos;s knowledge,
              capabilities,and self-awareness to become the best version of
              themselves and reach their personal goals.
            </p>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="box">
            <div className="icon">
              <FaHandPaper />
            </div>
            <h4 className="title">GBV Awareness</h4>
            <p className="description">
              {' '}
              We raise awareness to bring the issues to the forefront,
              advocating for change, celebrating survivors,
              and mourning those who have tragically lost
              their lives to domestic violence.
              {' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhatWeDo;
