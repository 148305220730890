import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { FaChevronRight } from 'react-icons/fa';
import Testimonial from '../Testimonial/Testimonial';

const QuickTestimonies = () => {
  const data = useStaticQuery(graphql`
  query TwoquickTestimonialsQuery {
    quickTestimonials: allMarkdownRemark(
      limit: 2,
      filter: {fileAbsolutePath: {regex: "/(testimonials)/.*\\.md$/"}}
      ) {
      people: nodes {
        frontmatter {
          name
          image
        }
        rawMarkdownBody
      }
    }
  }
  `);

  return (
    <section id="quickquickTestimonials" className="testimonials">
      <div className="container">
        <div className="row">
          {data.quickTestimonials.people
            .map(({ frontmatter, rawMarkdownBody }) => (
              <Testimonial
                name={frontmatter.name}
                image={frontmatter.image}
                content={rawMarkdownBody}
                key={frontmatter.image}
              />
            ))}
        </div>

        <Link to="testimonials" className="btn">
          More Testimonials
          {' '}
          <FaChevronRight />
        </Link>
      </div>
    </section>
  );
};

export default QuickTestimonies;
