import React from 'react';
import { Link } from 'gatsby';
import { FaBullseye, FaEye, FaChevronRight } from 'react-icons/fa';
import './quickAbout.css';

const QuickAbout = () => (
  <section id="why-us" className="why-us">
    <div className="container">

      <div className="row">
        <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-right">
          <div className="content">
            <h3>Main Objective</h3>
            <p>
              To create sustainable change and impact that will drive success
              in the lives of young girls and women.
              SheIsBrave strives to increase the capacity and the
              ability to bounce back into purpose, end
              period poverty, and promote the rights of girls and women.
            </p>
            <div className="text-center">
              <Link to="/about" className="more-btn">
                About Us
                {' '}
                <FaChevronRight />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-8 d-flex align-items-stretch">
          <div className="icon-boxes d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-xl-6 d-flex align-items-stretch">
                <div className="icon-box mt-4 mt-xl-0">
                  <FaBullseye />
                  <h4>Mission</h4>
                  <p>
                    To empower young women and girls to
                    unleash bravery and their full potential,
                    to encourage ambition confidence and strengthen
                    their characters, to have the ability to bounce back.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 d-flex align-items-stretch">
                <div className="icon-box mt-4 mt-xl-0">
                  <FaEye />
                  <h4>Vision</h4>
                  <p>
                    A platform in our world where every young
                    girl and women have the right mentors, demonstration of sisterhood.
                    Where women and girls become the best version of themselves not to
                    see themselves as victims but brave victors who overcame and can overcome.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
);

export default QuickAbout;
