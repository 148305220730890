import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './goals.css';

const Goals = () => (
  <div className="container">
    <section id="goals" className="goals">

      <div className="row">
        <div className="col-sm-3 col-12">
          <StaticImage src="../../images/goals/sdg.jpeg" className="img-fluid" alt="SDG" width={102} />
        </div>
        <div className="col-sm-3 col-12">
          <StaticImage src="../../images/goals/goal4.jpeg" className="img-fluid" alt="SDG goal 4" width={102} />
        </div>

        <div className="col-sm-3 col-12">
          <StaticImage src="../../images/goals/goal5.jpeg" className="img-fluid" alt="SDG goal 5" width={102} />
        </div>

        <div className="col-sm-3 col-12">
          <StaticImage src="../../images/goals/goal8.jpeg" className="img-fluid" alt="SDG goal 8" width={102} />
        </div>

      </div>

    </section>
  </div>
);

export default Goals;
