import React from 'react';
import './hero.css';

const Hero = () => (
  <section id="hero">
    <div className="hero-content">
      <h2>
        Helping young women and girls to
        be
        {' '}
        <span>brave</span>
        {' '}
      </h2>
      <p>
        empowered and skilled.
      </p>
      <div>
        <a href="https://forms.gle/1n2nT8YWEAmjnSbr9" className="btn-get-started">
          Join Us
        </a>
      </div>
    </div>
  </section>
);

export default Hero;
