import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Testimonial = ({
  image, name, content,
}) => (
  <div className="col-lg-6 mt-4">
    <div className="testimonial-item">
      <img src={image} alt={name} className="testimonial-img" />
      <h3>{name}</h3>
      <p>
        <FaQuoteLeft className="quote-icon-left" />
        {content}
        <FaQuoteRight className="quote-icon-right" />
      </p>
    </div>
  </div>
);
Testimonial.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Testimonial;
